export const getFetchedImage = (images, path) => {
  if (!images || !Array.isArray(images)) {
    throw new Error('No images array provided');
  }
  if (!path || typeof path !== 'string') {
    throw new Error('No image path or wrong type');
  }

  const foundImage = images.find((image) => {
    const imagePath = image?.node?.relativePath;

    if (!imagePath) {
      throw new Error('Image array does not contain nodes with relative paths');
    }

    return imagePath === path;
  });

  if (foundImage) {
    return foundImage.node;
  } else {
    throw new Error(`Image ${path} can't be found in query results`);
  }
};
