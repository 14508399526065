import React from 'react';
import { Trans } from 'react-i18next';

import { ImageCore, LOADING_OPTIONS, LinkWrapper, Text } from '@components';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { GA4_EVENTS } from '@shared/constants/google';
import { trackCustomGA4Event } from '@shared/google-utils';
import { formatLink } from '@shared/format-helpers';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { getFetchedImage } from '@shared/image-helpers';

import verSprite from '@images/home-page/versprite-logo.svg';

// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';

const LinkComponent = ({ link, children }) => (
  <LinkWrapper link={link}>
    <Text.Body2 as="span" color="primary" fontWeight="inherit">
      {children}
    </Text.Body2>
  </LinkWrapper>
);

const LinkComponentHeader = ({ link, children }) => (
  <LinkWrapper link={link} target="_blank">
    <Text.Body2 as="span" fontSize="inherit" $fontWeight="inherit" color="primary">
      {children}
    </Text.Body2>
  </LinkWrapper>
);

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('main:metaData.title'),
    description: t('main:metaData.description'),
    image: META_IMAGE,
    keywords: [`Atlas VPN`, `vpn`, `security`, `free`],
  }),
  headerCta: ({ ctaLink = PRICING_LINK, buttonProps }) => ({
    text: ({ t } = {}) => ({
      title: 'Atlas VPN service discontinued',
      description: (
        <div>
          <Text.Subheading2 fontWeight="600" pb={12}>
            Our VPN service is no longer available
          </Text.Subheading2>
          <Text.Body2>
            Thank you for your interest in Atlas VPN. We regret to inform you that as of April 2024,
            our VPN service has been permanently discontinued and is no longer available. We thank
            our customers for your support over the years.
          </Text.Body2>
        </div>
      ),
      buttons: [
        {
          title: t('common:links.learnMore'),
          link: '/blog/announcement',
          buttonProps,
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'home-page/hero-announcement.png'),
        alt: 'atlas devices',
        position: {
          mt: 24,
        },
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'home-page/hero-announcement.png'),
        alt: 'atlas devices',
        position: {
          mt: 24,
          height: 400,
        },
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'home-page/hero-announcement.png'),
        alt: 'atlas devices',
        position: {
          top: { lg: 86, xl: 30 },
          height: { lg: 440, xl: 556 },
          width: { lg: 600, xl: 690 },
          left: '77%',
        },
        loading: LOADING_OPTIONS.eager,
      },
    }),
  }),
  newsOutlets: (t) => ({
    title: t('main:news.title'),
  }),
  comparison: ({ t }) => ({
    title: t('main:comparison.title'),
  }),
  about: ({ t }) => ({
    title: t('main:contentBlocks.about.title'),
    description: (
      <Trans
        i18nKey="main:contentBlocks.about.description"
        components={[<LinkComponent key={0} link="/features/hide-ip" />]}
      />
    ),
    image: getFetchedImage(images, 'home-page/what-is-vpn.png'),
    link: {
      title: t('common:links.readMore'),
      link: formatLink(t('navigation:paths.what-is-vpn')),
      customEvent: { eventName: GA4_EVENTS.readMoreClick },
    },
  }),
  perks: ({ t }) => ({
    title: t('main:meetAtlasVpn'),
    description: t('main:ourMission'),
    data: [
      {
        image: getFetchedImage(images, 'home-page/open-internet.png'),
        title: t('main:contentBlocks.openInternet.title'),
        description: (
          <Trans
            i18nKey="main:contentBlocks.openInternet.description"
            components={[<LinkComponent key={0} link="/use-cases/unblock-websites" />]}
          />
        ),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
      {
        isReversed: true,
        image: getFetchedImage(images, 'home-page/data-encryption.png'),
        title: t('main:contentBlocks.provenSecurity.title'),
        description: (
          <Trans
            i18nKey="main:contentBlocks.provenSecurity.description"
            components={[<strong key={0} />]}
          />
        ),
        children: <ImageCore src={verSprite} alt="VerSprite" />,
        button: {
          title: t('main:contentBlocks.provenSecurity.securityAudit'),
          link: '/blog/atlas-vpn-completes-its-first-independent-security-audit-by-versprite',
          variant: 'outlined',
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.securityAuditClick },
          },
        },
      },
      {
        image: getFetchedImage(images, 'home-page/unlimited-protection.png'),
        title: t('main:contentBlocks.unlimitedProtection.title'),
        description: t('main:contentBlocks.unlimitedProtection.description'),
        showAvailablePlatforms: true,
      },
    ],
  }),
  countryList: ({ ctaLink = PRICING_LINK, t, buttonProps } = {}) => ({
    title: t('main:countryList.title'),
    description: t('main:countryList.description', { serverCount: SERVER_COUNT }),
    button: {
      link: ctaLink,
      title: t('common:actions.seeAllServers'),
      buttonProps,
    },
  }),
  features: ({ ctaLink = PRICING_LINK, t, buttonProps } = {}) => ({
    title: t('main:features.title'),
    data: [
      {
        title: t('main:features.fullSpeedConnectivity.title'),
        description: t('main:features.fullSpeedConnectivity.description'),
        image: {
          src: getFetchedImage(images, 'home-page/full-speed.png'),
          alt: 'Full-speed connectivity',
        },
      },
      {
        title: t('main:features.dataBreachMonitor.title'),
        description: t('main:features.dataBreachMonitor.description'),
        image: {
          src: getFetchedImage(images, 'home-page/breach-scanner.png'),
          alt: 'Data breach monitor',
        },
      },
      {
        title: t('main:features.networkKillSwitch.title'),
        description: t('main:features.networkKillSwitch.description'),
        image: {
          src: getFetchedImage(images, 'home-page/kill-switch.png'),
          alt: 'Network kill switch',
        },
      },
      {
        // span instead of string because of some bug that breaks this line on safari
        title: <span>{t('main:features.shieldMode.title')}</span>,
        description: t('main:features.shieldMode.description'),
        image: {
          src: getFetchedImage(images, 'home-page/safe-browse.png'),
          alt: 'shield mode',
        },
      },
    ],
    button: {
      title: t('common:actions.getAtlasVpn'),
      link: ctaLink,
      buttonProps,
    },
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
    onOpenItemDesktop: (item) =>
      trackCustomGA4Event({ eventName: GA4_EVENTS.benefitsItemClick(item + 1) }),
    onOpenItemMobile: ({ currentPage }) =>
      trackCustomGA4Event({ eventName: GA4_EVENTS.benefitsItemClick(currentPage + 1) }),
  }),
  reviews: (t) => ({
    title: t('main:reviews.title'),
  }),
  moneyBackGuarantee: ({ t }) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('main:pricingSection.title'),
    description: t('main:pricingSection.description'),
    footerProps: {
      text: t('main:pricingSection.notReadyYet'),
      button: {
        link: formatLink(t('navigation:paths.download')),
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: {
            eventName: GA4_EVENTS.plansDownloadClick,
          },
        },
      },
    },
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('main:footerCta.title'),
      description: t('main:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'Atlas footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'Atlas footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'Atlas footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'Atlas footer',
      },
      desktop: {
        src: footerImage,
        alt: 'Atlas footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
