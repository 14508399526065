import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '@containers';
import { Layout, SEO } from '@components';
import { PAGE_DATA } from '@shared/constants/pages/home-page';
import { DOMAIN, getNavItems } from '@shared/constants/constants';
import { GA4_EVENTS } from '@shared/constants/google';

const IndexPage = ({ location, data }) => {
  const { metaData, headerCta } = PAGE_DATA({ images: data.allFile.edges });

  const { t } = useTranslation();

  return (
    <Layout
      location={location}
      wrapperProps={{ backgroundColor: 'unset' }}
      navProps={{
        navItems: getNavItems({
          t,
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.getVpnClick },
          },
        }),
      }}
    >
      <Helmet>
        <link
          rel="canonical"
          href={`${DOMAIN.mainUrl}${location.pathname}`}
          data-react-helmet="true"
        />
      </Helmet>
      <SEO {...metaData(t)} />
      <Header
        data={headerCta({
          ctaLink: '/blog/announcement',
          buttonProps: {
            customEvent: { eventName: GA4_EVENTS.getVpnClick },
          },
        })}
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "main", "plans", "checkout", "campaigns"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "home-page/hero-announcement.png"
            "home-page/what-is-vpn.png"
            "home-page/open-internet.png"
            "home-page/data-encryption.png"
            "home-page/unlimited-protection.png"
            "home-page/full-speed.png"
            "home-page/breach-scanner.png"
            "home-page/kill-switch.png"
            "home-page/safe-browse.png"
            "product-page/30-day-guarantee.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
